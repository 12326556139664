import React from 'react';
// Layouts


// Views 
import Home from './views/Home';


const App = () => {


  return (
    <Home></Home>
  );
}

export default App;