import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import axios from 'axios';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  
  const [state, setState] = useState({
    onLoaded:true,
    name:"",
    phone_number:"",
    email:"",
    errors:{
      name:"",
      phone_number:"",
      email:"",
    }
  });

  const onChange = (e) => {
    let name = e.target.id;
    let value = e.target.value;
    if(state.onLoaded === false){
      if(state.errors[name] != ""){
        state.errors[name] = "";
      }
    }
    setState(prevState => ({
        ...prevState,
        [name]: value
    }));
  }

  const onSubmit = (e) => {
    
    setState(prevState => ({
      ...prevState,
      onLoaded:false,
      }));
    
      if (handleValidation() === false){
    } else{
      console.log("submit")
      console.log(state)
      axios.post(`/api/email`,{state})
      .then(res => {
        console.log(res)
      })
     
    }
    e.preventDefault();

    };

    const handleValidation = () => {
      let fields = state;
      let formIsValid = true;

      console.log("handleValidation")
      //Name
      console.log(state["name"] === "")
      if (state["name"] === "") {
        formIsValid = false;
        state.errors["name"] = "Cannot be empty";
      } else {
        state.errors["name"] = "";
      }
  
  
      //Email
      console.log(!fields["email"])
      if (!fields["email"]) {
        formIsValid = false;
        state.errors["email"] = "Cannot be empty";
      } else {
        state.errors["email"] = ""
      }
  
      if (typeof fields["email"] !== "undefined") {
        let lastAtPos = fields["email"].lastIndexOf("@");
        let lastDotPos = fields["email"].lastIndexOf(".");
  
        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["email"].indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            fields["email"].length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          state.errors["email"] = "Email is not valid";
        }
      }
      return formIsValid
  }

  const outerClasses = classNames(
    'cta section center-content-mobile',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  
  return (
    
    <section
      id="demo"
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
              Schedule a demo.
              </h3>
          </div>
            <form  className='form' >
              <input className={state.onLoaded || state.errors.name === "" ?  "input":"error-input" }  id="name" type="text" label="name" placeholder="Full Name" onChange={onChange} required />
              <input className={state.onLoaded || state.errors.phone_number === "" ?  "input":"error-input" } id="phone" type="text" label="phone_number" placeholder='Best number to reach you' onChange={onChange} required/>
              <input className={state.onLoaded || state.errors.email === "" ? "input":"error-input" } id="email" type="email" label="email" placeholder="Your best email" onChange={onChange} required/>
              <button className="button" type="submit" onClick={onSubmit}>Submit</button>
          </form>
        </div>
        {onSubmit && (<div id="snakcbar">Thank you! We will reach out to your in a day.</div>)}
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;