import React from 'react';
import classNames from 'classnames';
import Image from '../../elements/Image';

const Logo = ({
  className,
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      className={classes}
    >
      <h1 className="m-0">
          <Image
            src={require('./../../../assets/images/LOGO B.jpg')}
            alt="Open"
            width={320}
            height={320} />
      </h1>
    </div>
  );
}

export default Logo;