import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";

import App from './App';


//import './App.css';
import './assets/scss/style.scss';


ReactDOM.render(
    <App />,
  document.getElementById('root')
);


